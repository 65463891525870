import React from 'react';

/** material ui **/
import {fade, makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.text.divider,
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 320,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        marginBottom: 20,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '-0.6px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 17,
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 40,
        },
    },

    desc: {
        marginBottom: 30,
        color: theme.palette.grey.A000,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 18,
            fontSize: 12,
            letterSpacing: '-0.3px',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
            fontSize: 11,
            letterSpacing: '-0.28px',
            textAlign: 'center'
        },
    },

    browsers: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 125,

        [theme.breakpoints.down('md')]: {
            marginBottom: 60,
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 0,
        },
    },

    browserWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        marginBottom: 40
    },

    browser: {
        width: '100%',
        maxWidth: 152,
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            maxWidth: 81,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 71,
            margin: '0px 15px'
        },
    },

    browserIcon: {
        marginBottom: 10,

        [theme.breakpoints.down('sm')]: {
            marginBottom: 6,
        },
    },

    browserImg: {
        width: 152,
        height: 152,

        [theme.breakpoints.down('md')]: {
            width: 81,
            height: 81,
        },

        [theme.breakpoints.down('sm')]: {
            width: 71,
            height: 71,
        },
    },

    browserTitle: {
        marginBottom: 8,
        color: theme.palette.background.paper,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '0px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 4,
            fontSize: 12,
        },
    },

    browserTag: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: '0px 12px',
        minHeight: 25,
        background: theme.palette.background.paper,
        borderRadius: 16,
        color: theme.palette.text.divider,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 16,
        letterSpacing: '0px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            minHeight: 15,
            padding: '0px 6px',
            fontSize: 10,
        },
    },

    common: {
        padding: '68px 80px',
        background: fade(theme.palette.background.paper, 0.11),
        borderRadius: 60,

        [theme.breakpoints.down('md')]: {
            padding: '40px 0px 17px 40px',
            borderRadius: 38,
        },

        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px 24px 30px',
            textAlign: 'center'
        },
    },

    commonTitle: {
        marginBottom: 20,
        paddingRight: 30,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '-0.6px',

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            letterSpacing: '-0.4px',
        },
    },

    commonContent: {
        display: "flex",
        marginBottom: 30,

        [theme.breakpoints.down('md')]: {
            marginBottom: 23,
        },

        [theme.breakpoints.down('sm')]: {
            display: "block",
            textAlign: "left",
            marginBottom: 16,
        },
    },

    commonContentColumn: {
        display: "flex",
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'row'
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },

    margin1: {
        marginBottom: 11,

        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
        },
    },

    commonTag: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 78,
        height: 25,
        marginTop: 2,
        marginRight: 10,
        padding: theme.spacing(0.25, 0.5),
        color: '#464646',
        background: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 18,
        letterSpacing: '-0.45px',

        [theme.breakpoints.down('md')]: {
            minWidth: 51,
            height: 18,
            marginTop: 1,
            padding: '0px 4px',
            fontSize: 13,
            letterSpacing: '-0.33px',
            textAlign: 'center'
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 48,
            height: 17,
            marginBottom: 8,
            fontSize: 12,
            letterSpacing: '-0.3px',

        },
    },

    specDesc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.45px',

        [theme.breakpoints.down('md')]: {
            fontSize: 13,
            letterSpacing: '-0.33px',
        },

        [theme.breakpoints.down('sm')]: {
            display: 'block',
            fontSize: 11,
            letterSpacing: '-0.3px',
        },
    },
}));


export default function Spec() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.title}>서버 사양</div>
                {
                    isMobile ? (
                        <>
                            <div className={classes.browsers}>
                                <div className={classes.browserWrapper}>
                                    <div className={classes.browser}>
                                        <div className={classes.browserIcon}>
                                            <img className={classes.browserImg} src={"/images/browser/chrome.png"}/>
                                        </div>
                                        <div className={classes.browserTitle}>
                                            Chrome
                                        </div>
                                        <div className={classes.browserTag}>
                                            ALL
                                        </div>
                                    </div>

                                    <div className={classes.browser}>
                                        <div className={classes.browserIcon}>
                                            <img className={classes.browserImg} className={classes.browserImg}
                                                 src={"/images/browser/firefox.png"}/>
                                        </div>
                                        <div className={classes.browserTitle}>
                                            Firefox
                                        </div>
                                        <div className={classes.browserTag}>
                                            ALL
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.browserWrapper}>
                                    <div className={classes.browser}>
                                        <div className={classes.browserIcon}>
                                            <img className={classes.browserImg} src={"/images/browser/opera.png"}/>
                                        </div>
                                        <div className={classes.browserTitle}>
                                            Opera
                                        </div>
                                        <div className={classes.browserTag}>
                                            ALL
                                        </div>
                                    </div>

                                    <div className={classes.browser}>
                                        <div className={classes.browserIcon}>
                                            <img className={classes.browserImg} src={"/images/browser/safari.png"}/>
                                        </div>
                                        <div className={classes.browserTitle}>
                                            Safari
                                        </div>
                                        <div className={classes.browserTag}>
                                            Safari 9+
                                        </div>
                                    </div>

                                    <div className={classes.browser}>
                                        <div className={classes.browserIcon}>
                                            <img className={classes.browserImg} src={"/images/browser/edge.png"}/>
                                        </div>
                                        <div className={classes.browserTitle}>
                                            Edge
                                        </div>
                                        <div className={classes.browserTag}>
                                            ALL
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.desc}>IE10, Safari8 이하에서는 정상적인 동작을 하지 않을 수 있습니다. <br/>IOS 10 이상에서
                                정상동작합니다.<br/> 안드로이드 4.x 이상에서 동작합니다.
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.desc}>IE10, Safari8 이하에서는 정상적인 동작을 하지 않을 수 있습니다. <br/>IOS 10 이상에서
                                정상동작합니다.<br/> 안드로이드 4.x 이상에서 동작합니다.
                            </div>

                            <div className={classes.browsers}>
                                <div className={classes.browser}>
                                    <div className={classes.browserIcon}>
                                        <img className={classes.browserImg} src={"/images/browser/chrome.png"}/>
                                    </div>
                                    <div className={classes.browserTitle}>
                                        Chrome
                                    </div>
                                    <div className={classes.browserTag}>
                                        ALL
                                    </div>
                                </div>

                                <div className={classes.browser}>
                                    <div className={classes.browserIcon}>
                                        <img className={classes.browserImg} className={classes.browserImg}
                                             src={"/images/browser/firefox.png"}/>
                                    </div>
                                    <div className={classes.browserTitle}>
                                        Firefox
                                    </div>
                                    <div className={classes.browserTag}>
                                        ALL
                                    </div>
                                </div>

                                <div className={classes.browser}>
                                    <div className={classes.browserIcon}>
                                        <img className={classes.browserImg} src={"/images/browser/opera.png"}/>
                                    </div>
                                    <div className={classes.browserTitle}>
                                        Opera
                                    </div>
                                    <div className={classes.browserTag}>
                                        ALL
                                    </div>
                                </div>

                                <div className={classes.browser}>
                                    <div className={classes.browserIcon}>
                                        <img className={classes.browserImg} src={"/images/browser/safari.png"}/>
                                    </div>
                                    <div className={classes.browserTitle}>
                                        Safari
                                    </div>
                                    <div className={classes.browserTag}>
                                        Safari 9+
                                    </div>
                                </div>

                                <div className={classes.browser}>
                                    <div className={classes.browserIcon}>
                                        <img className={classes.browserImg} src={"/images/browser/edge.png"}/>
                                    </div>
                                    <div className={classes.browserTitle}>
                                        Edge
                                    </div>
                                    <div className={classes.browserTag}>
                                        ALL
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

                <div className={classes.common}>
                    <div className={classes.commonTitle}>서버 사양 공통</div>

                    <Grid container spacing={0}>
                        <Grid item xs={12} lg={6}>
                            <div className={classes.commonContent}>
                                <span className={classes.commonTag}>개발언어</span>
                                <span className={classes.specDesc}>NodeJS, JAVA(JDK 1.8)</span>
                            </div>

                            <div className={classes.commonContent}>
                                <span className={classes.commonTag}>OS</span>
                                <span className={classes.specDesc}>
                                        Windows 계열 (Windows Server 2008 이상) <br/> Linux 계열 (Cent OS 7.0 이상 / RedHat 7.0 이상)
                                    </span>
                            </div>


                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <div className={classes.commonContent}>
                                <span className={classes.commonTag}>SYSTEM</span>
                                <span
                                    className={classes.specDesc}>CPU : 최소 4c / 권장 8c <br/>Memory : 최소 8GB / 권장 16GB</span>
                            </div>

                            <div className={classes.commonContent}>
                                <span className={classes.commonTag}>DB</span>
                                <span className={classes.specDesc}>
                                        MySQL / MS SQL / ORACLE
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}