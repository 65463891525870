import React, {useState} from 'react';
import {makeStyles, fade, useTheme} from '@material-ui/core/styles';

/** icons **/
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        width: '100%',
        color: props => props.isDark ? theme.palette.background.paper : theme.palette.text.primary,
        background: props => props.isDark ? theme.palette.text.primary : theme.palette.background.paper,
    },

    section: {
        position: "relative",
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 100,
        margin: '0 auto',
        textAlign: 'left',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    logo: {
        width: 72.28,
        height: 45,
        marginBottom: 30,

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            width: 46.58,
            height: 29,
            marginBottom: 20,
        },
    },

    company: {
        marginBottom: 30,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            fontSize: 13,
            letterSpacing: '-0.33px',
            lineHeight: 1.5
        },
    },

    copyright: {
        color: props => props.isDark ? fade(theme.palette.background.paper, 0.5) : fade(theme.palette.text.primary, 0.5),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 13,
        letterSpacing: '0px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.33px',
            marginBottom: 30,
        },
    },

    pageUpIcon: {
        position: "absolute",
        bottom: 100,
        right: 0,
        width: 58,
        height: 58,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: props => props.isDark ? theme.palette.background.paper : theme.palette.text.primary,
        stroke: props => props.isDark ? theme.palette.background.paper : theme.palette.text.primary,
        borderRadius: '50%',
        cursor: "pointer",

        '&:hover': {
            borderColor: theme.palette.primary.main,
            stroke: theme.palette.primary.main
        },


        [theme.breakpoints.down('sm')]: {
            bottom: 30,
            width: 36,
            height: 36,
        },
    }
}));


export default function Footer({isDark = true, onScrollTop}) {
    const classes = useStyles({isDark});
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = () => {
        if (onScrollTop) {
            onScrollTop();
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div>
                    <img src={isDark ? "/icon/logo-symbol-white.svg" : "/icon/logo-symbol.svg"} className={classes.logo} style={{fill: isDark ? "#ffffff" : "#191919"}}/>
                </div>

                {
                    isMobile ? (
                        <div className={classes.company}>
                            (주)모카앤제이에스<br/>사업자등록번호: 219-81-31601 | 대표자: 김현준<br/>경기도 안양시 동안구 시민대로 248번길 25<br/>
                            <span style={{fontSize: 11}}>(관양동, 경기창조산업안양센터 1109호)</span>
                        </div>
                    ) : (
                        <div className={classes.company}>
                            (주)모카앤제이에스 | 사업자등록번호: 219-81-31601 | 대표자: 김현준 <br/>경기도 안양시 동안구 시민대로 248번길 25(관양동, 경기창조산업안양센터 1109호)
                        </div>
                    )
                }

                <div className={classes.copyright}>
                    Copyright © Mocha&JS. All Rights Reserved.
                </div>

                <img src={isDark ? "/icon/page-up-white.svg" : "/icon/page-up.svg"} className={classes.pageUpIcon} onClick={handleClick}/>
            </div>
        </div>
    );
}