import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useInView} from 'react-intersection-observer';

/** material **/
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';

/** icons **/
import {ReactComponent as ChevronIcon} from "../../icons/Chevron.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: "auto",
        background: "transparent url('/images/main_service.png') center no-repeat",
        backgroundSize: "cover",

        /*[theme.breakpoints.up('lg')]: {
            scrollSnapAlign: 'start',
            height: '100%',
        },*/
    },

    section: {
        position: "relative",
        width: '100%',
        maxWidth: 1200,
        minHeight: 796,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
            minHeight: 672,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
            minHeight: 1066,
        },
    },

    title: {
        marginBottom: 32,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 44,
        letterSpacing: '-1.1px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            fontSize: 27,
            letterSpacing: '-0.68px',
        },
    },

    desc: {
        marginBottom: 70,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            letterSpacing: '-0.35px',
        },
    },

    services: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },

    service: {
        width: 400,
        padding: '30px 28px',
        color: theme.palette.background.paper,
        stroke: theme.palette.background.paper,
        border: `1px solid ${theme.palette.background.paper}`,
        borderRight: "none",
        cursor: "pointer",

        '&:last-child': {
            borderRight: `1px solid ${theme.palette.background.paper}`,
        },

        '&:hover': {
            color: theme.palette.primary.main,
            stroke: theme.palette.primary.main,
        },

        [theme.breakpoints.down('md')]: {
            width: 214,
            padding: '30px 15px',
        },

        [theme.breakpoints.down('sm')]: {
            width: 280,
            marginBottom: 20,
            borderRight: `1px solid ${theme.palette.background.paper}`,
        },
    },

    serviceTitle: {
        marginBottom: 20,
        color: theme.palette.background.paper,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 16,
        letterSpacing: '0px',

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {},
    },

    serviceDesc: {
        marginBottom: 36,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            letterSpacing: '-0.33px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            letterSpacing: '-0.33px',
        },
    },

    serviceLink: {
        color: "inherit",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.3px',

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {},
    },

    link: {
        display: 'inline-flex',
        alignItems: 'center',
        color: "inherit",
        stroke: "inherit",
        textDecoration: "none",
        marginRight: 8,
    },

    icon: {
        width: 11,
        height: 11,
        marginLeft: 12,
    },
}));

export default function Service({duration = 1, delay = 0}) {
    const classes = useStyles();
    const history = useHistory();
    const elementRef = useRef();
    const theme = useTheme();
    const [isShow, setIsShow] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0.5,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (inView === true && !isShow) {
            setIsShow(true);
        }
    }, [inView]);


    return (
        <div className={classes.root}>
            <div className={classes.section}>
                {
                    isShow ? null : <div ref={ref}
                                         style={{
                                             width: '100%',
                                             height: '100%',
                                             position: 'absolute',
                                             top: 0,
                                             left: 0,
                                             opacity: 0,
                                             transform: 'translate3d(0, 5%, 0)'
                                         }}/>
                }


                <Slide direction="up" in={isShow} mountOnEnter unmountOnExit
                       {...(isShow ? {timeout: 1000} : {})}>
                    <div>
                        <div className={classes.title}>스마트 업무의 시작, <br/>모카앤제이에스</div>

                        {
                            isMobile ? (
                                <div className={classes.desc}>언택트 시대, 변화하는 새로운 업무 환경에 <br/>최적화 된 HTML5 기반의 스마트 솔루션</div>
                            ) : (
                                <div className={classes.desc}>언택트 시대, 변화하는 새로운 업무 환경에 최적화 된 HTML5 기반의 스마트 솔루션</div>
                            )
                        }
                    </div>
                </Slide>

                <div className={classes.services} onClick={() => {
                    history.push("/viewer")
                }}>
                    <Grow
                        in={isShow}
                        style={{transformOrigin: '0 0 0'}}
                        {...(isShow ? {timeout: 1500} : {})}>

                        <div className={classes.service}>
                            <div className={classes.serviceTitle}>M; Viewer</div>
                            <div className={classes.serviceDesc}>모바일 환경에 친화적인 <br/>HTML5 문서 뷰어</div>
                            <div className={classes.serviceLink}>
                                <Link to="/viewer" className={classes.link}>
                                    자세히 알아보기
                                    <ChevronIcon className={classes.icon}/>
                                </Link>
                            </div>
                        </div>
                    </Grow>

                    <Grow
                        in={isShow}
                        style={{transformOrigin: '0 0 0'}}
                        {...(isShow ? {timeout: 2000} : {})}>

                        <div className={classes.service} onClick={() => {
                            history.push("/editor")
                        }}>
                            <div className={classes.serviceTitle}>M; Block Editor</div>
                            <div className={classes.serviceDesc}>변화 된 업무 환경을 위한 <br/>블록 기반의 차세대 웹 에디터</div>
                            <div className={classes.serviceLink}>
                                <div className={classes.link}>
                                    자세히 알아보기
                                    <ChevronIcon className={classes.icon}/>
                                </div>
                            </div>
                        </div>
                    </Grow>

                    <Grow
                        in={isShow}
                        style={{transformOrigin: '0 0 0'}}
                        {...(isShow ? {timeout: 2500} : {})}>

                        <div className={classes.service} onClick={() => {
                            history.push("/sign")
                        }}>
                            <div className={classes.serviceTitle}>M; Sign</div>
                            {
                                <div className={classes.serviceDesc}>계약서 작성, 수정, 서명까지 <br/>가능한 스마트 워크스페이스</div>
                            }

                            <div className={classes.serviceLink}>
                                <div className={classes.link}>
                                    자세히 알아보기
                                    <ChevronIcon className={classes.icon}/>
                                </div>
                            </div>
                        </div>
                    </Grow>
                </div>
            </div>
        </div>
    );
}