import React, {useEffect, useRef} from 'react';
import Swiper from 'swiper';

/** material ui **/
import {makeStyles, useTheme} from '@material-ui/core/styles';

import {ReactComponent as ArrowRightIcon} from "../../icons/ArrowRight.svg";
import {ReactComponent as ArrowLeftIcon} from "../../icons/ArrowLeft.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        background: '#F6F7FB',
    },

    section: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 648,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    slider: {
        width: '100%',
        maxWidth: 850,
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            maxWidth: 460,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 250,
        }
    },

    img: {
        width: '100%',
        maxWidth: 613,
        height: "auto",

        [theme.breakpoints.down('md')]: {
            maxWidth: 510,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 250,
        }
    },

    button: {
        cursor: "pointer",
        '&:hover': {
            opacity: 0.7
        },
    },

    icon: {
        width: 62,
        height: 62,

        [theme.breakpoints.down('md')]: {
            width: 40,
            height: 40,
        },

        [theme.breakpoints.down('sm')]: {
            width: 27,
            height: 27,
        }
    }
}));


export default function Preview() {
    const classes = useStyles();
    const theme = useTheme();
    const elementRef = useRef(null);
    const swiper = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        swiper.current = new Swiper(elementRef.current, {})
    }, []);

    const handleNextClick = () => {
        swiper.current.slideNext(300, () => {
        });
    };

    const handlePrevClick = () => {
        swiper.current.slidePrev(300, () => {
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.button} onClick={handlePrevClick}>
                    <ArrowLeftIcon className={classes.icon}/>
                </div>

                <div className={classes.slider}>
                    <div className="swiper-container" ref={elementRef}>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                {
                                    isMobile ? (
                                        <img className={classes.img} src={"/images/sign/preview1.png"}/>
                                    ) : (
                                        <img className={classes.img} src={"/images/sign/preview1@2x.png"}/>
                                    )
                                }
                            </div>
                            <div className="swiper-slide">
                                {
                                    isMobile ? (
                                        <img className={classes.img} src={"/images/sign/preview2.png"}/>
                                    ) : (
                                        <img className={classes.img} src={"/images/sign/preview2@2x.png"}/>
                                    )
                                }
                            </div>
                            <div className="swiper-slide">
                                {
                                    isMobile ? (
                                        <img className={classes.img} src={"/images/sign/preview3.png"}/>
                                    ) : (
                                        <img className={classes.img} src={"/images/sign/preview3@2x.png"}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.button} onClick={handleNextClick}>
                    <ArrowRightIcon className={classes.icon}/>
                </div>
            </div>
        </div>
    );
}