// IE11의 경우
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {ToastContainer} from 'react-toastify';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './toastify.scss';



const serviceAppElement = document.getElementById('dp-app');

const lightTheme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 768,
            lg: 1200,
            xl: 1440,
        }
    },

    shadows: [
        "none",
        "0px 1px 4px rgba(21, 34, 50, 0.08)",
        "0px 2px 3px #00000014",
    ],

    shape: {
        borderRadius: 10,
    },

    palette: {
        primary: {
            main: "#1ab4b3",
        },

        secondary: {
            main: "#36CA82",  //
        },

        text: {
            primary: "#191919",                             // main
            secondary: "#767676",
            divider: "#2F2F2F",
        },

        background: {
            paper: '#FFFFFF',                                        //check
            default: '#F8F8FA',                             //check
            default1: '#F2F2F6',                            //check
        },

        error: {
            main: "#F0142F"                                 //check
        },

        grey: {
            A000: "#D5D5D5",                            //border
        }
    }
});

render(
    <>
        <BrowserRouter>
            <ThemeProvider theme={lightTheme}>
                <App/>
            </ThemeProvider>
        </BrowserRouter>
        <ToastContainer/>
    </>
    , serviceAppElement);

serviceWorker.unregister();
