import React, {useState} from 'react';

/** material ui **/
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        background: "transparent url('/images/featureBg.png') top center no-repeat",
        backgroundSize: "cover",
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 44,
        letterSpacing: '-1.2px',
        textAlign: 'left',

        [theme.breakpoints.down('md')]: {
            fontSize: 30,
            letterSpacing: '-0.75px',
            marginBottom: 30,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            letterSpacing: '-0.6px',
            textAlign: 'center',
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '-0.6px',
        textAlign: 'left',

        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            letterSpacing: '-0.35px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
            textAlign: 'center',
        },

    },

    imgWrapper: {
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            height: 210,
            overflow: 'hidden',
        },

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },

    img: {
        width: "100%",
        height: "auto",

        [theme.breakpoints.up('lg')]: {
            width: "auto",
            height: "auto",
            maxHeight: 400,
        },
    },

    box: {
        marginTop: 50,
        padding: '77px 36px 0px 75px',
        background: '#2F2F2F',
        borderRadius: 60,

        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            padding: '44px 26px 60px 48px',
            borderRadius: 60,
        },

        [theme.breakpoints.down('sm')]: {
            padding: '30px 30px 0px',
            marginBottom: 20,
            borderRadius: 30,
        },
    }
}));


export default function Feature() {
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <div className={classes.title}>M; Viewer의 문서 변환 기능을 <br/>통한 더 많은 문서 포맷 지원</div>
                    <div className={classes.box}>
                        <div className={classes.imgWrapper}>
                            <img className={classes.img} src={"/images/viewer/pdf_mobile.svg"}/>
                        </div>
                    </div>

                    <div className={classes.desc}>M; Viewer의 문서 변환 기능은 변환 모듈을 통해 <br/>MS Office 첨부 문서를 다운로드
                        없이 <br/>브라우저에서 문서를 열람할 수 있는 기능을 제공합니다
                    </div>
                </div>
            </div>
        );
    } else if (isTablet) {
        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <div className={classes.title}>M; Viewer의 문서 변환 기능을 통한 <br/>더 많은 문서 포맷 지원</div>
                    <div className={classes.box}>
                        <div className={classes.imgWrapper}>
                            <img className={classes.img} src={"/images/viewer/pdf_tablet.svg"}/>
                        </div>
                        <div className={classes.desc}>M; Viewer의 문서 변환 기능은 변환 모듈을 통해 MS Office 첨부 문서를 다운로드
                            없이 <br/>브라우저에서 문서를 열람할 수 있는 기능을 제공합니다
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <div className={classes.title}>M; Viewer의 문서 변환 기능을 통한 <br/>더 많은 문서 포맷 지원</div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.desc}>M; Viewer의 문서 변환 기능은 변환 모듈을 통해 <br/>MS Office 첨부 문서를 다운로드
                            없이 브라우저에서 <br/>문서를 열람할 수 있는 기능을 제공합니다
                        </div>
                    </Grid>
                </Grid>

                <div className={classes.box}>
                    <div className={classes.imgWrapper}>
                        <img className={classes.img} src={"/images/viewer/pdf_pc.svg"}/>
                    </div>
                </div>

            </div>
        </div>
    );
}