import React, {useRef, useState} from 'react';
import {useScroll} from '../hooks/useScrollHook';

/** material ui **/
import {makeStyles, useTheme} from '@material-ui/core/styles';

import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import Slider from './Viewer/Slider';
import Feature from './Viewer/Feature';
import Spec from './Viewer/Spec';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper
    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: 624,
        background: "transparent url('/images/viewer.png') top center no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            marginTop: 64,
        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        marginBottom: 40,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 56,
        letterSpacing: '-1.4px',
        textAlign: "left",

        [theme.breakpoints.down('md')]: {
            marginBottom: 30,
            fontSize: 40,
            letterSpacing: '-1px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
            letterSpacing: '0px',
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '-0.6px',
        textAlign: "left",

        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            letterSpacing: '-0.38px',
            lineHeight: 1.5
        },
    },
}));

const pathList = [
    {
        href: "/",
        name: "홈"
    },
    {
        href: "/viewer",
        name: "서비스"
    },
    {
        name: "M; Viewer"
    },
];

export default function Main() {
    const classes = useStyles();
    const containerRef = useRef();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollSpeed = 100;
    const {scrollToY} = useScroll({scrollSpeed, containerRef});
    const handleScrollTop = () => {
        scrollToY(0);
    };

    return (
        <div className={classes.root} ref={containerRef} style={{position: 'relative', overflowY: 'auto'}}>
            <Header/>

            {
                isTablet ? null : <Nav pathList={pathList}/>
            }

            <div className={classes.container}>
                <div className={classes.section}>
                    <div className={classes.title}>모바일 환경 맞춤형 <br/>스마트 웹 문서 뷰어</div>

                    {
                        isMobile ? (
                            <div className={classes.desc}>별도의 프로그램 설치 없이 문서 확인이 가능한 <br/>HTML5 문서 뷰어 솔루션입니다. <br/>모바일 및 등기우편 발송에 최적화 된
                                모바일 뷰어를 <br/>제공하고 있습니다.
                            </div>
                        ) : (
                            <div className={classes.desc}>별도의 프로그램 설치 없이 문서 확인이 가능한 HTML5 문서 뷰어 솔루션입니다. <br/>모바일 및 등기우편 발송에 최적화 된
                                모바일 뷰어를 제공하고 있습니다.
                            </div>
                        )
                    }
                </div>
            </div>

            <Slider/>
            <Feature/>
            <Spec/>

            <Footer isDark={false} onScrollTop={handleScrollTop}/>
        </div>
    );
}