import React from 'react';
import {useHistory} from "react-router-dom";

/** material **/
import {makeStyles, useTheme, withStyles, fade} from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';

import DesktopHeader from './DesktopHeader';

/** icons **/
import {ReactComponent as LogoIcon} from "../icons/Logo.svg"
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: 1100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: 'fixed',
        top: 0,
        left: 'auto',
        right: 0,
        width: '100%',
        background: (props) => {
            if (props.color) {
                if (props.color === "transparent") {
                    return "transparent";
                } else if (props.color === "dark") {
                    return theme.palette.text.primary;
                }
            }

            return theme.palette.background.paper;
        },
    },

    toolbar: {
        width: '100%',
        maxWidth: 1200,
        height: 64,
        padding: theme.spacing(0, 2.5),
        transition: 'background ease 0.5s 0s',
    },

    logo: {
        width: 158,
        height: 20,
        cursor: "pointer",
        fill: (props) => {
            if (props.color) {
                return theme.palette.background.paper;
            }

            return theme.palette.text.primary;
        },
    },

    actions: {
        display: 'flex',
    },

    button: {
        padding: 0,
        width: 64,
        height: 64,
        justifyContent: 'flex-end',
        marginLeft: 6,
        color: (props) => {
            if (props.color) {
                return theme.palette.background.paper;
            }

            return theme.palette.text.primary;
        },

        fontFamily: '"Poppins", sans-serif',
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: 0,
    },

    link: {
        color: "inherit",
        textDecoration: "none",
        marginRight: 8,

        '&:hover': {
            color: "inherit",
        }
    },

    closeButton: {
        position: 'absolute',
        width: 64,
        height: 64,
        top: 0,
        right: 0,
        color: theme.palette.background.paper
    },
}));

const StyledList = withStyles((theme) => ({
    root: {
        minHeight: 360,
        backgroundColor: theme.palette.text.primary,
        padding: theme.spacing(6, 2.5),
    },
}))(List);

const StyledListItem = withStyles((theme) => ({
    root: {
        minHeight: 55,
        padding: 0,
        borderBottom: `1px solid ${theme.palette.background.paper}`,
        color: theme.palette.background.paper,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        fontSize: 15,
        letterSpacing: '0px',
    },
}))(ListItem);

const StyledSubListItem = withStyles((theme) => ({
    root: {
        minHeight: 48,
        color: fade(theme.palette.background.paper, 0.5),
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        fontSize: 15,
        letterSpacing: '0px',
    },
}))(ListItem);

export default function Header(props) {
    const {color} = props;
    const classes = useStyles({color});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();

    const [isMobileMenu, setIsMobileMenu] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const toggleIsMobileMenu = (event) => {
        setIsMobileMenu(!isMobileMenu);
    };

    const toggleIsMobileMenuOpen = (event) => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleClickContactUs = () => {
        history.push("/contact");
    };

    if (isMobile) {
        return (
            <div className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo} onClick={() => {
                        history.push("/");
                    }}>
                        <LogoIcon/>
                    </div>

                    <div className={classes.grow}/>
                    <div className={classes.actions}>
                        <Button className={classes.button} onClick={toggleIsMobileMenu}>
                            <MenuIcon style={{fontSize: 28}}/>
                        </Button>
                    </div>
                </Toolbar>

                <Drawer anchor={'top'}
                        open={isMobileMenu}
                        elevation={1}
                        onClose={toggleIsMobileMenu}>
                    <div>
                        <StyledList
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classes.root}>
                            <StyledListItem button onClick={toggleIsMobileMenuOpen}>
                                Service
                                {isMobileMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                            </StyledListItem>
                            <Collapse in={isMobileMenuOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <StyledSubListItem button onClick={() => {
                                        history.push("/viewer");
                                    }}>
                                        M; Viewer
                                    </StyledSubListItem>
                                    <StyledSubListItem button onClick={() => {
                                        history.push("/editor");
                                    }}>
                                        M; Block Editor
                                    </StyledSubListItem>
                                    <StyledSubListItem button onClick={() => {
                                        history.push("/sign");
                                    }}>
                                        M; Sign
                                    </StyledSubListItem>
                                </List>
                            </Collapse>

                            <StyledListItem button onClick={handleClickContactUs}>
                                Contact us
                            </StyledListItem>
                        </StyledList>

                        <Button className={classes.closeButton} onClick={toggleIsMobileMenu}>
                            <CloseIcon style={{fontSize: 28}}/>
                        </Button>
                    </div>
                </Drawer>
            </div>
        );
    }

    return <DesktopHeader {...props}/>;
}