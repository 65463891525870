import React from 'react';
import {Link} from "react-router-dom";
import {makeStyles, fade} from '@material-ui/core/styles';

/** icons **/
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 64,
        marginTop: 64,
        color: fade(theme.palette.text.primary, 0.5),
        background: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.35px',
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
        },
    },

    link: {
        color: fade(theme.palette.text.primary, 0.5),
        textDecoration: "none",
        marginRight: 8,

        '&:hover': {
            color: theme.palette.text.primary
        }
    },

    accent: {
        color: theme.palette.text.primary
    }
}));


export default function Nav({pathList = []}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                {pathList.map((path, index) => {
                    if (path.href) {
                        return (
                            <React.Fragment key={`nav-path-${index}`}>
                                <Link to={path.href} className={classes.link}>{path.name}</Link>
                                <span style={{marginRight: 8}}>></span>
                            </React.Fragment>
                        )
                    } else {
                        return <span key={`nav-path-${index}`} className={classes.accent}>{path.name}</span>
                    }
                })}
            </div>
        </div>
    );
}