import React, {useState} from 'react';
import clsx from "clsx";
import {useHistory} from "react-router-dom";

/** material **/
import {makeStyles, fade} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

/** icons **/
import {ReactComponent as LogoIcon} from "../icons/Logo.svg"

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: 1100,
        position: 'fixed',
        top: 0,
        left: 'auto',
        right: 0,
        width: '100%',
        height: 64,
        overflow: 'hidden',
        transition: 'height ease 0.3s, background ease 0.3s',
        background: (props) => {
            if (props.color) {
                if (props.color === "transparent") {
                    return "transparent";
                } else if (props.color === "dark") {
                    return theme.palette.text.primary;
                }
            }

            return theme.palette.background.paper;
        }
    },

    extendAppbar: {
        height: 128,
    },

    reverseAppbar: {
        background: (props) => {
            if (props.color) {
                if (props.color === "transparent" || props.color === "dark") {
                    return theme.palette.text.primary;
                }
            }

            return theme.palette.background.paper;
        }
    },

    list: {
        width: '100%',
        padding: 0,
    },

    listItem: {
        padding: 0,
        justifyContent: 'center',
        alignItems: 'flex-start'
    },

    toolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'relative',
        width: '100%',
        maxWidth: 1200,
        padding: theme.spacing(0),
        overflow: 'hidden',
    },

    logo: {
        display: 'flex',
        alignItems: 'center',
        width: 158,
        height: 64,
        cursor: "pointer",
    },

    logoIcon: {
        width: 'auto',
        height: 20,
        fill: (props) => {
            if (props.color) {
                return theme.palette.background.paper;
            }

            return theme.palette.text.primary;
        },
    },

    actions: {
        display: 'flex',
    },

    buttonWrapper: {},

    button: {
        minWidth: 131,
        height: 64,
        marginLeft: 6,
        textTransform: "none",
        color: (props) => {
            if (props.color) {
                return fade(theme.palette.background.paper, 0.5);
            }

            return fade(theme.palette.text.primary, 0.5);
        },

        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        fontSize: 15,
        letterSpacing: 0,

        '&:hover': {
            color: (props) => {
                if (props.color) {
                    return theme.palette.background.paper;
                }
                return theme.palette.text.primary;
            },
        }
    },

    activeButton: {
        color: (props) => {
            if (props.color) {
                return theme.palette.background.paper;
            }
            return theme.palette.text.primary;
        },

        '&:hover': {
            textDecoration: 'underline',
            textUnderlinePosition: 'under',
        }
    },

    buttonUnderline: {
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
    },

    link: {
        color: "inherit",
        textDecoration: "none",
        marginRight: 8,

        '&:hover': {
            color: "inherit",
        }
    },

    closeButton: {
        position: 'absolute',
        top: 30,
        right: 20,
        color: theme.palette.background.paper
    },
}));

export default function Header({color}) {
    const classes = useStyles({color});
    const history = useHistory();

    const [isExtend, setIsExtend] = useState(false);
    const [isReverseAppbar, setIsReverseAppbar] = useState(false);

    const handleClickContactUs = () => {
        history.push("/contact");
    };

    return (
        <div className={clsx(classes.appBar, {
            [classes.extendAppbar]: isExtend,
            [classes.reverseAppbar]: isReverseAppbar,
        })}>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <div className={classes.toolbar}>
                        <div className={classes.logo} onClick={() => {
                            history.push("/");
                        }}>
                            <LogoIcon className={classes.logoIcon}/>
                        </div>
                        <div className={classes.grow}/>
                        <div className={classes.actions}>
                            <div
                                onMouseEnter={() => {
                                    setIsExtend(true);
                                    setIsReverseAppbar(true)
                                }}
                                onMouseLeave={() => {
                                    setIsExtend(false);
                                    setIsReverseAppbar(false)
                                }}>
                                <div style={{textAlign: 'right'}}>
                                    <Button className={clsx(classes.button, classes.activeButton, {
                                        [classes.buttonUnderline]: isExtend && isReverseAppbar,
                                    })}>
                                        Service
                                    </Button>
                                </div>
                                <div>
                                    <Button className={classes.button} onClick={() => {
                                        history.push("/viewer");
                                    }}>
                                        M; Viewer
                                    </Button>
                                    <Button className={classes.button} onClick={() => {
                                        history.push("/editor");
                                    }}>
                                        M; Block Editor
                                    </Button>
                                    <Button className={classes.button} onClick={() => {
                                        history.push("/sign");
                                    }}>
                                        M; Sign
                                    </Button>
                                </div>
                            </div>

                            <Button className={clsx(classes.button, classes.activeButton, {
                                [classes.buttonUnderline]: !isExtend && isReverseAppbar,
                            })}
                                    onClick={handleClickContactUs}
                                    onMouseEnter={() => {
                                        setIsReverseAppbar(true)
                                    }}
                                    onMouseLeave={() => {
                                        setIsReverseAppbar(false)
                                    }}>
                                Contact us
                            </Button>
                        </div>
                    </div>
                </ListItem>
            </List>
        </div>
    );
}