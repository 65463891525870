import React, {useState} from 'react';

/** material ui **/
import {makeStyles, fade, withStyles, useTheme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        background: theme.palette.text.primary,
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    tabs: {
        marginBottom: 88,

        [theme.breakpoints.down('md')]: {
            marginBottom: 56,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    feature: {
        width: '100%',
        height: '100%',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",

    },

    imgWrapper: {
        width: '100%',
        marginBottom: 50,
        textAlign: "center",

        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },

    img: {
        width: "auto",
        height: 500,

        [theme.breakpoints.down('md')]: {
            height: 288,
        },

        [theme.breakpoints.down('sm')]: {
            height: 215,
        },
    },

    title: {
        marginBottom: 73,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 44,
        letterSpacing: '-1.2px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
            fontSize: 30,
            letterSpacing: '-0.75px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 0,
            fontSize: 22,
            letterSpacing: '-0.6px',
        },
    },

    featureDesc: {
        width: '100%',
        height: '100%',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },

    text: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 18,
        letterSpacing: '-0.45px',
        marginBottom: 4,

        [theme.breakpoints.down('md')]: {
            fontSize: 15,
            letterSpacing: '-0.38px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 11,
            letterSpacing: '-0.6px',
        },
    },

    check: {
        marginRight: 13,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: 18,
        letterSpacing: '-0.45px',

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            letterSpacing: '-0.35px',
        },
    },

}));

const StyledTabs = withStyles((theme) => ({
    flexContainer: {
        justifyContent: "center",

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },

    indicator: {
        display: 'none'
    },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: 61,
        padding: 0,
        color: fade(theme.palette.background.paper, 0.5),
        borderColor: "transparent",
        margin: '0 30px',

        [theme.breakpoints.down('md')]: {
            minWidth: 10,
            margin: '0 25px',
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 10,
            margin: '0 5px',
        },
    },

    selected: {
        color: theme.palette.background.paper,
        borderColor: theme.palette.background.paper
    },

    wrapper: {
        maxHeight: 40,
        color: "inherit",
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderBottomColor: 'inherit',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 24,

        letterSpacing: '-0.6px',

        '&:hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            letterSpacing: '-0.5px',

        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            letterSpacing: '-0.35px',
        },
    }
}))(Tab);

export default function Slider() {
    const classes = useStyles();
    const theme = useTheme();

    const [tab, setTab] = useState(0);
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    if (isMobile) {
        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <StyledTabs className={classes.tabs}
                                value={tab}
                                onChange={handleChangeTab}>
                        <StyledTab label="블록 컴포넌트"/>
                        <StyledTab label="반응형 콘텐츠"/>
                        <StyledTab label="페이지 공유"/>
                        <StyledTab label="동적 서식 제공"/>
                    </StyledTabs>

                    {
                        tab === 0 ? (
                            <div className={classes.feature}>
                                <div className={classes.imgWrapper}>
                                    <img className={classes.img} src={"/images/editor/editor1.png"}/>
                                </div>

                                <div className={classes.title}>문단, 테이블, 이미지, 비디오, <br/>오디오 등 모든 블록을 쉽게 <br/>추가하고 이동할 수
                                    있습니다.
                                </div>
                            </div>
                        ) : tab === 1 ? (
                            <div className={classes.feature}>
                                <div className={classes.imgWrapper}>
                                    <img className={classes.img} src={"/images/editor/editor2.png"}/>
                                </div>

                                <div className={classes.title}>
                                    하나의 문서로 데스크톱, <br/>태블릿 PC, 모바일을 위한 <br/>컨텐츠를 제공할 수 있습니다.
                                </div>
                            </div>
                        ) : tab === 2 ? (
                            <div className={classes.feature}>
                                <div className={classes.imgWrapper}>
                                    <img className={classes.img} src={"/images/editor/editor3.png"}/>
                                </div>

                                <div className={classes.title}>
                                    만들어진 컨텐츠를 뷰어 모드를 <br/>통해 언제 어디서나 <br/>컨텐츠를 열람할 수 있습니다.
                                </div>
                            </div>
                        ) : (
                            <div className={classes.feature}>
                                <div className={classes.imgWrapper}>
                                    <img className={classes.img} src={"/images/editor/editor4.png"}/>
                                </div>

                                <div className={classes.title}>
                                    서식을 만들고 동적으로 <br/>데이터를 연동하여 <br/>문서를 쉽게 제작할 수 있습니다.
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <StyledTabs className={classes.tabs}
                            value={tab}
                            onChange={handleChangeTab}>
                    <StyledTab label="블록 컴포넌트"/>
                    <StyledTab label="반응형 콘텐츠"/>
                    <StyledTab label="페이지 공유"/>
                    <StyledTab label="동적 서식 제공"/>
                </StyledTabs>

                {
                    tab === 0 ? (
                        <div className={classes.feature}>
                            <div className={classes.title}>문단, 테이블, 이미지, 비디오, 오디오 등 <br/>모든 블록을 쉽게 추가하고 이동할 수
                                있습니다.
                            </div>

                            <div className={classes.imgWrapper}>
                                <img className={classes.img} src={"/images/editor/editor1@2x.png"}/>
                            </div>
                        </div>
                    ) : tab === 1 ? (
                        <div className={classes.feature}>
                            <div className={classes.title}>
                                블록 구조로 하나의 문서로 데스크톱, 태블릿 PC, <br/>모바일을 위한 컨텐츠를 제공할 수 있습니다.
                            </div>

                            <div className={classes.imgWrapper}>
                                <img className={classes.img} src={"/images/editor/editor2@2x.png"}/>
                            </div>
                        </div>
                    ) : tab === 2 ? (
                        <div className={classes.feature}>
                            <div className={classes.title}>
                                만들어진 컨텐츠를 뷰어 모드를 통해 <br/>언제 어디서나 컨텐츠를 열람할 수 있습니다.
                            </div>

                            <div className={classes.imgWrapper}>
                                <img className={classes.img} src={"/images/editor/editor3@2x.png"}/>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.feature}>
                            {
                                isTablet ? (
                                    <div className={classes.title}>
                                        서식을 만들고 동적으로 데이터를 연동하여 <br/>문서들을 쉽게 제작할 수 있습니다.
                                    </div>
                                ) : (
                                    <div className={classes.title}>
                                        서식을 만들고 동적으로 데이터를 연동하여 <br/>문서들을 쉽게 제작할 수 있습니다.
                                    </div>
                                )
                            }

                            <div className={classes.imgWrapper}>
                                <img className={classes.img} src={"/images/editor/editor4@2x.png"}/>
                            </div>

                            <div className={classes.featureDesc}>
                                <div>
                                    <div className={classes.text}>
                                        <span className={classes.check}>✓</span>
                                        공통 서식 + 데이터 연동
                                    </div>
                                    <div className={classes.text}>
                                        <span className={classes.check}>✓</span>
                                        자동 문서 생성 (데이터 연동)
                                    </div>
                                    <div className={classes.text}>
                                        <span className={classes.check}>✓</span>
                                        서식만 만들면 끝
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}