import React, {useState} from 'react';
import useMediaQuery from "@material-ui/core/useMediaQuery";

/** material ui **/
import {makeStyles, fade, withStyles, useTheme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

/** icon **/
import {ReactComponent as Sign1Icon} from "../../icons/SignIcon1.svg";
import {ReactComponent as Sign2Icon} from "../../icons/SignIcon2.svg";
import {ReactComponent as Sign3Icon} from "../../icons/SignIcon3.svg";
import {ReactComponent as Sign4Icon} from "../../icons/SignIcon4.svg";
import {ReactComponent as Sign5Icon} from "../../icons/SignIcon5.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        background: theme.palette.background.paper,

        [theme.breakpoints.down('md')]: {
            background: theme.palette.text.primary
        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    tabs: {
        marginBottom: 88,

        [theme.breakpoints.down('md')]: {
            marginBottom: 50,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    feature: {},

    imgWrapper: {
        width: '100%',
        textAlign: "left",

        [theme.breakpoints.down('md')]: {
            textAlign: "center",
            marginBottom: 22,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        },
    },

    img: {
        width: 663,
        height: "auto",

        [theme.breakpoints.down('md')]: {
            width: 376,
        },

        [theme.breakpoints.down('sm')]: {
            width: 280,
        },
    },

    featureDesc: {
        width: '100%',
        height: '100%',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        paddingLeft: 30,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            alignItems: "center",
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        },
    },

    title: {
        marginBottom: 60,
        color: theme.palette.text.primary,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 44,
        letterSpacing: '-1.2px',
        textAlign: "left",

        [theme.breakpoints.down('md')]: {
            textAlign: "center",
            marginBottom: 40,
            color: theme.palette.background.paper,
            fontSize: 30,
            letterSpacing: '-0.75px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 24,
            fontSize: 22,
            letterSpacing: '-0.6px',
        },
    },

    textWrapper: {
        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
            width: 'auto',
            textAlign: 'left'
        },

        [theme.breakpoints.down('sm')]: {},
    },

    text: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 18,
        letterSpacing: '-0.45px',
        marginBottom: 4,

        [theme.breakpoints.down('md')]: {
            textAlign: "left",
            color: theme.palette.background.paper,
            fill: theme.palette.background.paper,
            fontSize: 15,
            letterSpacing: '-0.38px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            letterSpacing: '-0.6px',
            marginBottom: 4
        },
    },

    check: {
        marginRight: 13,
        color: theme.palette.secondary.main,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: 18,
        letterSpacing: '-0.45px',

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            letterSpacing: '-0.35px',
            marginBottom: 4
        },
    },

    emoji: {
        width: 18,
        height: 18,
        marginRight: 11
    },

    icon: {
        width: 16,
        height: 18,
        marginRight: 9,
    }
}));

const StyledTabs = withStyles((theme) => ({
    flexContainer: {
        justifyContent: "center"
    },

    indicator: {
        display: 'none'
    },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: 61,
        padding: 0,
        color: fade(theme.palette.text.primary, 0.5),
        borderColor: "transparent",
        margin: '0 30px',

        [theme.breakpoints.down('md')]: {
            color: fade(theme.palette.background.paper, 0.5),
            minWidth: 10,
            margin: '0 25px',
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 10,
            margin: '0 15px',
        },
    },

    selected: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,

        [theme.breakpoints.down('md')]: {
            color: theme.palette.background.paper,
            borderColor: theme.palette.background.paper,
        },
    },

    wrapper: {
        maxHeight: 40,
        color: "inherit",
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderBottomColor: 'inherit',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '-0.6px',

        '&:hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            letterSpacing: '-0.35px',
        },
    }
}))(Tab);

export default function Slider() {
    const classes = useStyles();
    const theme = useTheme();

    const [tab, setTab] = useState(0);
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <StyledTabs className={classes.tabs}
                            value={tab}
                            onChange={handleChangeTab}>
                    <StyledTab label="계약서 작성"/>
                    <StyledTab label="계약서 서명"/>
                    <StyledTab label="계약서 보안"/>
                </StyledTabs>


                <div className={classes.feature}>
                    <Grid container>
                        <Grid item xs={12} lg={7}>
                            <div className={classes.imgWrapper}>
                                {
                                    tab === 0 ? <img className={classes.img} src={"/images/sign/sign1@2x.png"}/> :
                                        tab === 1 ? <img className={classes.img} src={"/images/sign/sign2@2x.png"}/> :
                                            <img className={classes.img} src={"/images/sign/sign3@2x.png"}/>
                                }
                            </div>

                        </Grid>
                        <Grid item xs={12} lg={5}>
                            {
                                tab === 0 ? (
                                        <div className={classes.featureDesc}>
                                            {
                                                isMobile ? <div className={classes.title}>M; Block Editor를 통한 <br/>쉬운 계약서 작성
                                                    </div> :
                                                    isTablet ?
                                                        <div className={classes.title}>M; Block Editor를 통한 쉬운 계약서 작성</div> :
                                                        <div className={classes.title}>M; Block Editor를 통한 <br/>쉬운 계약서 작성
                                                        </div>
                                            }

                                            <div>
                                                <div className={classes.textWrapper}>
                                                    <div className={classes.text}>
                                                        <Sign1Icon className={classes.icon}/>
                                                        다양한 계약서 서식 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <Sign2Icon className={classes.icon}/>
                                                        공통 데이터 연동
                                                    </div>
                                                    <div className={classes.text}>
                                                        <Sign3Icon className={classes.icon}/>
                                                        히스토리 관리
                                                    </div>
                                                    <div className={classes.text}>
                                                        <Sign4Icon className={classes.icon}/>
                                                        계약서 재사용 가능
                                                    </div>
                                                    <div className={classes.text}>
                                                        <Sign5Icon className={classes.icon}/>
                                                        주소록 지원
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    tab === 1 ? (
                                            <div className={classes.featureDesc}>
                                                <div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        다양한 디바이스 환경에서 서명 가능 (모바일, 태블릿, PC)
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        다양한 서명방식(이미지, 도장, 캔버스, 텍스트)
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        일괄서명/순차서명 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        일괄/개별 암호화 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        다채널 인증 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        보안 모듈 연동
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        대량발송 지원
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <div className={classes.featureDesc}>
                                                {
                                                    isMobile ?
                                                        <div className={classes.title}>타임스탬프, DRM 등 <br/>다양한 모듈과 연계 가능
                                                        </div> :
                                                        isTablet ?
                                                            <div className={classes.title}>타임스탬프, DRM 등 다양한 모듈과 연계
                                                                가능</div> :
                                                            <div className={classes.title}>타임스탬프, DRM 등 <br/>다양한 모듈과 연계
                                                                가능</div>
                                                }
                                            </div>
                                        )
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}