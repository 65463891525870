import React from 'react';

/** component **/
import {Redirect, Route, Switch} from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Main from "./views/Main";
import Viewer from "./views/Viewer";
import Sign from "./views/Sign";
import Editor from "./views/Editor";
import ContactUs from "./views/ContactUs";

/** css **/
import 'swiper/swiper.scss';
import './App.css';

function App(location) {
    location = window.location;

    return (
        <>
            <Switch location={location}>
                <Route exact path="/viewer" component={Viewer}/>
                <Route exact path="/editor" component={Editor}/>
                <Route exact path="/sign" component={Sign}/>
                <Route exact path="/contact" component={ContactUs}/>
                <Route exact path="/" component={Main}/>

                <Redirect to="/"/>
            </Switch>
        </>
    );
}

export default App;
