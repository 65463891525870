import React, {useRef} from 'react';
import {useScroll} from '../hooks/useScrollHook';

/** material ui **/
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';

/** component **/
import Slider from './Sign/Slider';
import Preview from './Sign/Preview';
import Spec from './Sign/Spec';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper
    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: 624,
        background: "transparent url('/images/sign.png') top center no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            marginTop: 64,

        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        marginBottom: 40,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 56,
        letterSpacing: '-1.4px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            fontSize: 40,
            letterSpacing: '-1px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
            letterSpacing: '0px',
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '-0.6px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            letterSpacing: '-0.38px',
        },
    },
}));

const pathList = [
    {
        href: "/",
        name: "홈"
    },
    {
        href: "/sign",
        name: "서비스"
    },
    {
        name: "M; Sign"
    },
];

export default function Main() {
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const containerRef = useRef();

    const scrollSpeed = 100;
    const {scrollToY} = useScroll({scrollSpeed, containerRef});
    const handleScrollTop = () => {
        scrollToY(0);
    };

    return (
        <div className={classes.root} ref={containerRef} style={{position: 'relative', overflowY: 'auto'}}>
            <Header/>

            {
                (isTablet || isMobile) ? null : <Nav pathList={pathList}/>
            }

            <div className={classes.container}>
                <div className={classes.section}>
                    {
                        isMobile ?
                            <>
                                <div className={classes.title}>계약서 작성부터 수정, <br/>발송, 서명까지 가능한 <br/>전자계약/결재 솔루션</div>
                                <div className={classes.desc}>M; Sign은 웹 에디터를 적용한 <br/>최초의 전자계약/결재 솔루션 입니다.</div>
                            </> : isTablet ?
                            <>
                                <div className={classes.title}>계약서 작성부터 <br/>수정, 발송, 서명까지 가능한 <br/>전자계약/결재 솔루션</div>
                                <div className={classes.desc}>M; Sign은 웹 에디터를 적용한 최초의 전자계약/결재 솔루션 입니다.</div>
                            </> : <>
                                <div className={classes.title}>계약서 작성부터 수정, 발송, 서명까지 가능한 <br/>전자계약/결재 솔루션</div>
                                <div className={classes.desc}>M; Sign은 웹 에디터를 적용한 최초의 전자계약/결재 솔루션 입니다.</div>
                            </>
                    }
                </div>
            </div>

            <Slider/>
            <Preview/>
            <Spec/>

            <Footer isDark={false} onScrollTop={handleScrollTop}/>
        </div>
    );
}