import React, {useRef, useState, useEffect} from 'react';
import {useScroll} from '../hooks/useScrollHook';
import {makeStyles} from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import Service from './Main/Service';
import Client from './Main/Client';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
        /*[theme.breakpoints.up('lg')]: {
            scrollSnapType: 'y mandatory',
        },*/

    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: '100%',
        background: "transparent url('/images/main.png') center no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.up('lg')]: {
            scrollSnapAlign: 'start',
        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        marginBottom: 34,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 68,
        letterSpacing: '-1.7px',

        [theme.breakpoints.down('md')]: {
            fontSize: 56,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '-0.6px',

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
}));

export default function Main() {
    const classes = useStyles();
    const containerRef = useRef();
    const [isReverseHeader, setIsReverseHeader] = useState(false);

    const scrollSpeed = 100;
    const {scrollToY, scrollToElement} = useScroll({scrollSpeed, containerRef});
    const handleScrollTop = () => {
        scrollToY(0);
    };

    useEffect(() => {
        function onScroll(event) {
            const element = containerRef.current;
            const currentPosition = element.scrollY || element.pageYOffset || element.scrollTop;

            if (currentPosition > 32) {
                setIsReverseHeader(true);
            } else {
                setIsReverseHeader(false);
            }
        }

        containerRef.current.addEventListener("scroll", onScroll);
        return () => containerRef.current.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className={classes.root}
             ref={containerRef}
             style={{position: 'relative', overflowY: 'auto'}}>

            <Header color={isReverseHeader ? "dark" : "transparent"}/>

            <div className={classes.container}>
                <div className={classes.section}>
                    <div className={classes.title}>HTML5 기반의 <br/>스마트 언택트 플랫폼</div>
                    <div className={classes.desc}>비대면 업무의 확대에 맞추어 <br/>스마트 워크플로우를 위한 서비스를 제공합니다.</div>
                </div>
            </div>

            <Service/>

            <Client/>

            <Footer isDark={true} onScrollTop={handleScrollTop}/>
        </div>
    );
}