import React, {useRef, useState} from 'react';
import {useScroll} from '../hooks/useScrollHook';
import {toast} from 'react-toastify';
import validator from "validator";

/** material ui **/
import {makeStyles, withStyles, fade, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

/** define **/
import Axios from '_define/Axios';

/** component **/
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import useMediaQuery from "@material-ui/core/useMediaQuery";

/** icons **/

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper
    },

    container: {
        display: "flex",
        alignItems: "center",
        width: '100%',
        background: "transparent url('/images/contact_us.png') top center no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            marginTop: 64,
        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        color: theme.palette.background.paper,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 56,
        letterSpacing: '0.34px',
        marginBottom: 50,

        [theme.breakpoints.down('md')]: {
            marginBottom: 20,
            letterSpacing: '0.34px',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
            fontSize: 32,
            letterSpacing: '0.19px',
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '-0.5px',
        marginBottom: 50,

        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            letterSpacing: '-0.4px',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            fontSize: 14,
            letterSpacing: '-0.35px',
        },
    },

    formGroup: {
        width: '100%',
        maxWidth: 620,

        [theme.breakpoints.up('lg')]: {
            maxWidth: 620,
        },
    },

    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        minWidth: 120,
    },

    formLabel: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
        },
    },

    spacing: {
        marginTop: 300,

        [theme.breakpoints.down('md')]: {
            marginTop: 300,
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: 128,
        },
    },

    action: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    termTitle: {
        color: fade(theme.palette.background.paper, 0.7),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.35px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
        },
    },

    sendButton: {
        color: theme.palette.background.paper,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '-0.14px',
        textDecoration: 'underline',

        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
        },

        [theme.breakpoints.down('md')]: {
            '&:hover': {
                color: theme.palette.background.paper,
                textDecoration: 'underline',
            },
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            letterSpacing: '0.1px',

            '&:hover': {
                color: theme.palette.background.paper,
                textDecoration: 'underline',
            },
        },
    },

    textFieldWrapper: {
        display: "flex",
        alignItems: "flex-end",
        height: 381,
        overflow: 'hidden',

        [theme.breakpoints.down('md')]: {
            height: 297,
        },

        [theme.breakpoints.down('sm')]: {
            height: 152,
        },
    },

    textField: {
        margin: 8,
    },

    textFieldInputRoot: {
        marginBottom: 8,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
            marginBottom: 15,
        },
    },

    textFieldInputUnderline: {
        '&:before': {
            borderColor: theme.palette.background.paper
        },

        '&:hover:not(.Mui-disabled):before': {
            borderColor: theme.palette.background.paper
        },
    },

    textFieldInputLabel: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
        },
    },

    error: {
        color: theme.palette.error.main,
        margin: 8,
        fontSize: '0.75rem',
        marginTop: 3,
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.6,
        letterSpacing: '0.03333em',
    }
}));

const StyledInput = withStyles((theme) => ({
    root: {
        marginBottom: 4,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
            marginBottom: 15,
        },
    },

    input: {
        minHeight: 32,
    },

    underline: {
        '&:before': {
            borderColor: theme.palette.background.paper
        },

        '&:hover:not(.Mui-disabled):before': {
            borderColor: theme.palette.background.paper
        },
    },
}))(Input);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minHeight: 44,
        padding: theme.spacing(0, 2.5),
        color: fade(theme.palette.text.primary, 0.7),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.35px',

        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            letterSpacing: '-0.4px'
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            letterSpacing: '-0.3px',
        },

        '&:focus': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(MenuItem);

const StyledSelect = withStyles((theme) => ({
    icon: {
        color: theme.palette.background.paper,
    },
}))(Select);

const MenuProps = {
    elevation: 0,
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    }
};

const pathList = [
    {
        href: "/",
        name: "홈"
    },
    {
        name: "문의하기"
    },
]

export default function Main() {
    const classes = useStyles();
    const containerRef = useRef();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [checked, setChecked] = useState(false);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');
    const [error, setError] = useState({});

    const scrollSpeed = 100;
    const {scrollToY} = useScroll({scrollSpeed, containerRef});
    const handleScrollTop = () => {
        scrollToY(0);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleClick = () => {
        const {isError = false, error = {}} = validate();

        if (isError) {
            setError(error);
        } else {
            Axios.post("/addComment", {
                type: type,
                title: title,
                email: email,
                comments: comments,
            }).then(({data}) => {
                const {result} = data;

                if(result && result === 1){
                    notify({
                        content: <div>소중한 의견 감사합니다. <br/>확인 후 빠르게 답변 드리겠습니다</div>
                    });

                    setError({
                        type: false,
                        title: false,
                        email: false,
                        comments: false,
                        checked: false,
                    });

                    setChecked(false);
                    setType('');
                    setTitle('');
                    setEmail('');
                    setComments('');
                } else {
                    notify({
                        content: <div>에러가 발생하였습니다. <br/>잠시 후 다시 시도해 주세요</div>
                    });
                }
            }).catch(() => {
                notify({
                    content: <div>에러가 발생하였습니다. <br/>잠시 후 다시 시도해 주세요</div>
                });
            });

        }
    };

    const validate = () => {
        const obj = {
            type: false,
            title: false,
            email: false,
            comments: false,
            checked: false,
        };

        let isError = false;
        if (type === '' || !(type > 0 && type < 5)) {
            obj.type = true;
            isError = true;
        }

        if (validator['isEmpty'](title) || title.length < 5) {
            obj.title = true;
            isError = true;
        }

        if (validator['isEmpty'](email) || !validator['isEmail'](email)) {
            obj.email = true;
            isError = true;
        }

        if (validator['isEmpty'](comments) || comments.length < 10) {
            obj.comments = true;
            isError = true;
        }

        if (checked === false) {
            obj.checked = true;
            isError = true;
        }

        return {
            isError: isError,
            error: obj
        };
    };

    const notify = (props) => {
        const {content} = props;
        const TOAST = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            pauseOnFocusLoss: false,
            style: {
                whiteSpace: 'break-spaces'
            }
        }

        if (toast) {
            toast["dark"](content, {...TOAST});
        } else {
            alert(content);
        }
    };

    return (
        <div className={classes.root} ref={containerRef} style={{position: 'relative', overflowY: 'auto'}}>
            <Header/>
            {
                isTablet ? null : <Nav pathList={pathList}/>
            }

            <div className={classes.container}>
                <div className={classes.section}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <div className={classes.title}>Contact Us</div>
                            <div className={classes.desc}>문의사항에 따라 이용해주세요. <br/> 확인 후 답변 드리도록 하겠습니다.</div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <div className={classes.formGroup}>
                                <FormControl className={classes.formControl}
                                             error={!!(error && error.type)}
                                             fullWidth>
                                    <InputLabel className={classes.formLabel}>
                                        문의 종류를 선택해주세요.
                                    </InputLabel>
                                    <StyledSelect value={type}
                                                  onChange={handleChangeType}
                                                  MenuProps={MenuProps}
                                                  input={<StyledInput/>}>
                                        <StyledMenuItem value={1}>
                                            제품 문의
                                        </StyledMenuItem>

                                        <StyledMenuItem value={2}>
                                            기술 문의
                                        </StyledMenuItem>

                                        <StyledMenuItem value={3}>
                                            제휴 문의
                                        </StyledMenuItem>

                                        <StyledMenuItem value={4}>
                                            기타 문의
                                        </StyledMenuItem>
                                    </StyledSelect>

                                    {
                                        !!(error && error.type) ? <FormHelperText>문의 종류를 선택하세요</FormHelperText> : null
                                    }
                                </FormControl>

                                <FormControl className={classes.formControl}
                                             error={!!(error && error.title)}
                                             fullWidth>
                                    <InputLabel className={classes.formLabel}>제목을 입력해주세요.</InputLabel>
                                    <StyledInput value={title}
                                                 onChange={(event) => {
                                                     setTitle(event.target.value)
                                                 }}/>

                                    {
                                        !!(error && error.title) ?
                                            <FormHelperText>제목을 입력하세요 (5글자 이상)</FormHelperText> : null
                                    }
                                </FormControl>

                                <FormControl className={classes.formControl}
                                             error={!!(error && error.email)}
                                             fullWidth>
                                    <InputLabel className={classes.formLabel}>이메일을 입력해주세요.</InputLabel>
                                    <StyledInput value={email}
                                                 onChange={(event) => {
                                                     setEmail(event.target.value)
                                                 }}/>
                                    {
                                        !!(error && error.email) ?
                                            <FormHelperText>유효한 이메일을 입력하세요</FormHelperText> : null
                                    }
                                </FormControl>

                                <div className={classes.textFieldWrapper}>
                                    <TextField
                                        label="문의 내용을 입력해주세요."
                                        multiline
                                        fullWidth
                                        rowsMax={isMobile ? 3 : isTablet ? 6 : 9}
                                        margin="normal"
                                        variant="standard"
                                        error={!!(error && error.comments)}
                                        helperText={!!(error && error.comments) ? "내용을 입력하세요 (10글자 이상)" : null}
                                        value={comments}
                                        onChange={(event) => {
                                            setComments(event.target.value)
                                        }}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textFieldInputLabel,
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldInputRoot,
                                                underline: classes.textFieldInputUnderline
                                            }
                                        }}
                                    />
                                </div>

                                <div className={classes.action}>
                                    <span>
                                        <Checkbox
                                            color="primary"
                                            value={checked}
                                            onChange={() => {
                                                setChecked(!checked)
                                            }}

                                        />
                                        <span className={classes.termTitle}>이메일 수집에 동의합니다.</span>
                                    </span>

                                    <Button className={classes.sendButton} onClick={handleClick}>SEND</Button>
                                </div>

                                {
                                    error.checked === true ?
                                        <div className={classes.error}>이메일 수집 동의가 필요합니다.</div> : null
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Footer isDark={false} onScrollTop={handleScrollTop}/>
        </div>
    );
}