import {useEffect, useRef, useState, useLayoutEffect} from 'react';

const cancelAllAnimationFrames = (frames) => {
    frames.forEach(frame => window.cancelAnimationFrame(frame));
};

const getScrollTop = (ref) => ref.current.scrollTop;
const getScrollHeight = (ref) => ref.current.scrollHeight;
const getClientHeight = (ref) => ref.current.clientHeight;

const atBottomOfContainer = (containerRef) =>
    getScrollTop(containerRef) + getClientHeight(containerRef) === getScrollHeight(containerRef);

export const useScroll = (
    options = {}
) => {
    const {scrollSpeed = 40, containerRef = {current: document.documentElement}} = options;
    const animationFrames = useRef([]);
    const lastScrollPosition = useRef(0);
    const isScrollingUp = useRef(false);
    const isScrollingDown = useRef(false);

    const [y, setY] = useState(-1);

    const cancelScrolling = () => {
        cancelAllAnimationFrames(animationFrames.current);
        animationFrames.current = [];
        isScrollingUp.current = false;
        isScrollingDown.current = false;
    };

    const userDidScrollInOppositeDirection = () => {
        if (isScrollingUp.current && getScrollTop(containerRef) > lastScrollPosition.current) {
            return true;
        } else if (isScrollingDown.current && getScrollTop(containerRef) < lastScrollPosition.current) {
            return true;
        }
        return false;
    };

    const performScroll = (y) => {
        cancelScrolling();
        lastScrollPosition.current = getScrollTop(containerRef);
        animationFrames.current.push(
            window.requestAnimationFrame(function step() {
                const scrollTop = getScrollTop(containerRef);
                const difference = y - scrollTop;
                if (Math.abs(difference) < 1) {
                    cancelScrolling();
                    return;
                } else if (difference < 0) {
                    isScrollingUp.current = true;
                    const delta = Math.abs(difference) < scrollSpeed ? difference : -scrollSpeed;
                    containerRef.current.scrollTop = getScrollTop(containerRef) + delta;
                    return animationFrames.current.push(window.requestAnimationFrame(step));
                } else if (difference > 0) {
                    if (atBottomOfContainer(containerRef)) {
                        cancelScrolling();
                        return;
                    }
                    isScrollingDown.current = true;
                    const delta = Math.abs(difference) < scrollSpeed ? difference : scrollSpeed;
                    containerRef.current.scrollTop = getScrollTop(containerRef) + delta;
                    return animationFrames.current.push(window.requestAnimationFrame(step));
                }
            })
        );
    };


    useEffect(() => {
        containerRef.current.addEventListener('scroll', () => {
            if (userDidScrollInOppositeDirection()) cancelScrolling();
            lastScrollPosition.current = getScrollTop(containerRef);
            setY(-1);
        });
    }, []);

    useLayoutEffect(() => {
        if (y >= 0) {
            performScroll(y);
        }
    }, [y]);

    const scrollToY = (y) => setY(y < 0 ? 0 : y);
    const scrollToElement = (element, verticalOffset = 0) => {
        const elementOffsetTop = element?.current?.offsetTop || 0;
        scrollToY(elementOffsetTop - verticalOffset);
    };

    return {scrollToY, scrollToElement};
};