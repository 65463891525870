import React, {useRef} from 'react';
import {useScroll} from '../hooks/useScrollHook';

/** material ui **/
import {makeStyles, useTheme} from '@material-ui/core/styles';

import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';

import Slider from './Editor/Slider';
import Preview from './Editor/Preview';
import Spec from './Editor/Spec';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper
    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: 624,
        background: "transparent url('/images/editor.png') top center no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            marginTop: 64,

        },
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    title: {
        marginBottom: 40,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 56,
        letterSpacing: '-1.4px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            marginBottom: 30,
            fontSize: 40,
            letterSpacing: '-1px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
            marginBottom: 20,
            fontSize: 32,
            letterSpacing: '0px',
        },
    },

    desc: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '-0.6px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
            fontSize: 15,
            letterSpacing: '-0.38px',
            lineHeight: 1.5
        },
    },
}));

const pathList = [
    {
        href: "/",
        name: "홈"
    },
    {
        href: "/editor",
        name: "서비스"
    },
    {
        name: "M; Block Editor"
    },
];

export default function Editor() {
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const containerRef = useRef();

    const scrollSpeed = 100;
    const {scrollToY} = useScroll({scrollSpeed, containerRef});
    const handleScrollTop = () => {
        scrollToY(0);
    };

    return (
        <div className={classes.root} ref={containerRef} style={{position: 'relative', overflowY: 'auto'}}>
            <Header/>

            {
                (isTablet || isMobile) ? null : <Nav pathList={pathList}/>
            }

            <div className={classes.container}>
                <div className={classes.section}>
                    {
                        isMobile ?
                            <>
                                <div className={classes.title}>변화 된 환경을 위한 <br/>HTML5 기반의 <br/>차세대 웹 에디터</div>
                                <div className={classes.desc}>M; Block Editor는 글 작성과 페이지를 <br/>블록 구조로 만들어 사용자의 생각대로 <br/>제작할 수
                                    있는
                                    새로운 웹 에디터 입니다.
                                </div>
                            </>
                            : <>
                                <div className={classes.title}>변화 된 환경을 위한 HTML5 기반의 <br/>차세대 웹 에디터</div>
                                <div className={classes.desc}>M; Block Editor는 글 작성과 페이지를 블록 구조로 만들어 <br/>사용자의 생각대로 제작할 수 있는
                                    새로운 웹 에디터 입니다.
                                </div>
                            </>
                    }
                </div>
            </div>

            <Slider/>
            <Preview/>
            <Spec/>

            <Footer isDark={false} onScrollTop={handleScrollTop}/>
        </div>
    );
}