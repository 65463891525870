import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: theme.palette.background.paper,
        /*[theme.breakpoints.up('lg')]: {
            height: '100%',
            scrollSnapAlign: 'start',
            overflowY: "scroll",
            scrollbarWidth: 0,
        },*/
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 640,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },

    title: {
        color: theme.palette.text.divider,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        fontSize: 44,

        letterSpacing: '0px',
        textAlign: "center",

        [theme.breakpoints.down('md')]: {
            marginBottom: 30,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 27,
        },
    },

    desc: {
        color: theme.palette.text.divider,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.4px',
        textAlign: "center",


        [theme.breakpoints.down('md')]: {
            marginBottom: 60,
        },

        [theme.breakpoints.down('sm')]: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: 14,
            marginBottom: 40,
        },
    },

    listWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        height: 200,
        marginTop: 80,

        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            height: 739,
        },
    },

    list: {
        width: '100%',
        height: '100%',
    },

    listItem: {
        width: 286,
        height: 92,
        marginBottom: 50,
    },

    accent: {
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontSize: 44,

        [theme.breakpoints.down('sm')]: {
            fontSize: 27,
            letterSpacing: '0px'
        },
    },

    img: {
        width: 214,
        height: 71,
        marginBottom: 30,

        [theme.breakpoints.down('sm')]: {
            width: 140,
            height: 47,
            marginBottom: 19,
        },
    }
}));

const clientList = [
    {
        id: "sida",
        src: "/images/with/sida.png"
    },
    {
        id: "nhis",
        src: "/images/with/nhis.svg"
    },
    {
        id: "kodit",
        src: "/images/with/kodit.svg"
    },
    {
        id: "axa",
        src: "/images/with/axa.png"
    },
    {
        id: "transcosmos",
        src: "/images/with/transcosmos.png"
    },
    {
        id: "carrot",
        src: "/images/with/carrot.png"
    },
    {
        id: "canon",
        src: "/images/with/canon.png"
    },
    {
        id: "samsunglife",
        src: "/images/with/samsunglife.png"

    },
    {
        id: "kbinsure",
        src: "/images/with/kbinsure.png"

    },
    {
        id: "hi",
        src: "/images/with/hi.svg"
    },
    {
        id: "kibo",
        src: "/images/with/kibo.svg"
    },
    {
        id: "aca",
        src: "/images/with/aca.png"

    },
];

export default function Client() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <div className={classes.titleWrapper}>
                            <div className={classes.title}>
                                <span className={classes.accent}>With</span> Mocha&JS
                            </div>
                            <div className={classes.desc}>
                                함께하기에 모카앤제이에스는 성장하고 있습니다.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                            {
                                clientList.map(({id, src}, index) => (
                                    <Grid item key={`client-${id}-${index}`} xs={6} md={4}>
                                        <img className={classes.img} src={src}/>
                                    </Grid>
                                ))
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}