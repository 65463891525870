import React, {useState} from 'react';

/** material ui **/
import {makeStyles, fade, withStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        background: "#2E2E2E",
    },

    section: {
        width: '100%',
        maxWidth: 1200,
        paddingTop: 160,
        paddingBottom: 160,
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
            paddingBottom: 100,
            maxWidth: 700,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 310,
            paddingTop: 64,
            paddingBottom: 64,
        },
    },

    feature: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },

    imgWrapper: {
        width: '100%',
        textAlign: "center",

        [theme.breakpoints.down('sm')]: {
            height: 320,
            marginBottom: 30,
            overflow: 'hidden'
        },
    },

    featureDesc: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
    },

    tabs: {
        marginBottom: 88,
        [theme.breakpoints.down('md')]: {
            marginBottom: 50,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    img: {
        width: "auto",
        height: 752,

        [theme.breakpoints.down('md')]: {
            height: 479
        },
    },

    title: {
        width: '100%',
        marginBottom: 60,
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 44,

        letterSpacing: '-1.2px',
        textAlign: 'left',

        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
            fontSize: 30,
            letterSpacing: '-0.75px',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 24,
            fontSize: 22,
            letterSpacing: '-0.6px',
        },
    },

    textContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
        },
    },

    textWrapper: {
        display: 'inline-flex',
        flexDirection: 'column',
    },

    text: {
        color: theme.palette.background.paper,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 18,
        letterSpacing: '-0.45px',
        textAlign: 'left',

        [theme.breakpoints.down('md')]: {
            fontSize: 15,
            letterSpacing: '-0.38px',
        },

        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            fontSize: 11,
            letterSpacing: '-0.6px',
            marginBottom: 6
        },
    },

    check: {
        marginRight: 13,
        color: fade(theme.palette.background.paper, 0.8),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: 18,
        letterSpacing: '-0.45px',

        [theme.breakpoints.down('md')]: {},

        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            letterSpacing: '-0.35px',
            marginBottom: 6
        },
    },
}));

const StyledTabs = withStyles((theme) => ({
    flexContainer: {
        justifyContent: 'flex-start',

        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },

    indicator: {
        display: 'none'
    },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: 61,
        padding: 0,
        color: fade(theme.palette.background.paper, 0.5),
        borderColor: "transparent",
        margin: '0 30px',

        '&:first-child': {
            marginLeft: 0
        },

        [theme.breakpoints.down('md')]: {
            minWidth: 10,
            margin: '0 25px',
            '&:first-child': {
                marginLeft: 30
            },
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 10,
            margin: '0 15px',
        },

    },

    selected: {
        color: theme.palette.background.paper,
        borderColor: theme.palette.background.paper,
    },

    wrapper: {
        maxHeight: 40,
        color: "inherit",
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderBottomColor: 'inherit',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
        fontSize: 24,

        letterSpacing: '-0.6px',

        '&:hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },

        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            fontSize: 20,
            letterSpacing: '-0.5px',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            letterSpacing: '-0.35px',
        },
    }
}))(Tab);

export default function Slider() {
    const classes = useStyles();
    const theme = useTheme();
    const [tab, setTab] = useState(0);

    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    if (isTablet || isMobile) {
        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <StyledTabs className={classes.tabs}
                                value={tab}
                                onChange={handleChangeTab}>
                        <StyledTab label="등기우편"/>
                        <StyledTab label="전자책"/>
                        <StyledTab label="그룹웨어"/>
                    </StyledTabs>

                    {
                        tab === 0 ? (
                            <div className={classes.feature}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.imgWrapper}>
                                            {
                                                isMobile ? (
                                                    <img className={classes.img}
                                                         src={"/images/viewer/viewer1_mobile.svg"}/>
                                                ) : (
                                                    <img className={classes.img} src={"/images/viewer/viewer1.svg"}/>
                                                )
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.featureDesc}>
                                            {
                                                isMobile ?
                                                    <div className={classes.title}>모바일 기반의 디지털 안내·고지 <br/>발송서비스에 최적화 된
                                                        문서 뷰어</div> :
                                                    <div className={classes.title}>모바일 기반의 디지털 <br/>안내·고지 발송서비스에 <br/>최적화
                                                        된 문서 뷰어</div>

                                            }
                                            <div className={classes.textContainer}>
                                                <div className={classes.textWrapper}>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        카카오톡, 크롬, 사파리 등 모바일 브라우저 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        스와이프, 핀치 줌 등 모바일 제스처 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        법적 의무화를 위한 암호화 및 자동 다운로드 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        사회적 약자를 위한 TTS 지원
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : tab === 1 ? (
                            <div className={classes.feature}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.imgWrapper}>
                                            <img className={classes.img} src={"/images/viewer/viewer2.png"}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.featureDesc}>
                                            <div className={classes.title}>PDF 기반의 전자책을 위한 <br/>문서 뷰어</div>

                                            <div className={classes.textContainer}>
                                                <div className={classes.textWrapper}>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        모바일 기반의 책 넘김 효과 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        미리보기, 목차, 검색 기능 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        북마크, 주석 기능 지원
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <div className={classes.feature}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.imgWrapper}>
                                            <img className={classes.img} src={"/images/viewer/viewer3.png"}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.featureDesc}>
                                            <div className={classes.title}>기업 내 스마트 워크 환경<br/>구축을 위한 문서 뷰어</div>
                                            <div className={classes.textContainer}>
                                                <div className={classes.textWrapper}>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        멀티 OS, 멀티 브라우저, 멀티 디바이스를 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        기존 시스템과 연동을 위한 API 지원
                                                    </div>
                                                    <div className={classes.text}>
                                                        <span className={classes.check}>✓</span>
                                                        워터마크, 인쇄, 다운로드 권한 등 보안 기능 지원
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <div className={classes.imgWrapper}>
                            {
                                tab === 0 ? (
                                    <img className={classes.img} src={"/images/viewer/viewer1.svg"}/>
                                ) : tab === 1 ? (
                                    <img className={classes.img} src={"/images/viewer/viewer2@2x.png"}/>
                                ) : (
                                    <img className={classes.img} src={"/images/viewer/viewer3@2x.png"}/>
                                )
                            }
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <div className={classes.feature}>
                            <StyledTabs className={classes.tabs}
                                        value={tab}
                                        onChange={handleChangeTab}>
                                <StyledTab label="등기우편"/>
                                <StyledTab label="전자책"/>
                                <StyledTab label="그룹웨어"/>
                            </StyledTabs>

                            {
                                tab === 0 ? (
                                    <div className={classes.featureDesc}>
                                        <div className={classes.title}>모바일 기반의 디지털 안내·고지 <br/>발송서비스에 최적화 된 문서 뷰어</div>
                                        <div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                카카오톡, 크롬, 사파리 등 모바일 브라우저 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                스와이프, 핀치 줌 등 모바일 제스처 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                법적 의무화를 위한 암호화 및 자동 다운로드 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                사회적 약자를 위한 TTS 지원
                                            </div>
                                        </div>
                                    </div>
                                ) : tab === 1 ? (
                                    <div className={classes.featureDesc}>
                                        <div className={classes.title}>PDF 기반의 전자책을 위한 <br/>문서 뷰어</div>

                                        <div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                모바일 기반의 책 넘김 효과 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                미리보기, 목차, 검색 기능 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                북마크, 주석 기능 지원
                                            </div>
                                            <div className={classes.text}>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={classes.featureDesc}>
                                        <div className={classes.title}>기업 내 스마트 워크 환경을 <br/>구축을 위한 문서 뷰어</div>
                                        <div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                멀티 OS, 멀티 브라우저, 멀티 디바이스를 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                기존 시스템과 연동을 위한 API 지원
                                            </div>
                                            <div className={classes.text}>
                                                <span className={classes.check}>✓</span>
                                                워터마크, 인쇄, 다운로드 권한 등 보안 기능 지원
                                            </div>
                                            <div className={classes.text}>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}