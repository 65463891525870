import axios from 'axios';

/** config **/
axios.interceptors.request.use(async function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    return Promise.reject(error);
});

export default axios;
